<template>
    <div>
        <zw-select-group v-model="inputVal"
                         :options="getCountries()"
                         name="new_value"
                         :label-prefix="labelPrefix"
                         icon="globe-europe"
                         text-field="name"
                         value-field="iso2"
                         rules="required"
        ></zw-select-group>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        'value': {},
        'labelPrefix': {},
    },
    methods: {
        ...mapGetters('CommonData', ['getCountries']),
    },
    mounted() {
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>